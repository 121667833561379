<script setup>
useHead({
    title: `Скачать приложение ${useRuntimeConfig().public.appName}`,
});

const config = useRuntimeConfig();

</script>
<template>
    <div class="container-lg">
        <div class="row justify-content-center">
            <div class="col col-md-6 col-md-4">
                <div class="app-page py-5">
                    <div v-if="config.public.isDomino" class="text-center">
                        <h1 style="font-size: 20px;font-weight: 600;">Кэшбэк 10% с каждого заказа</h1>
                        <p style="font-size: 16px;font-weight: 400;">Копи и обменивай баллы в приложении DOMИNO PIZZA
                        </p>
                    </div>
                    <div v-if="config.public.isDomino" class="d-flex flex-column">
                        <div class="mx-auto mb-2">
                            <a href="https://play.google.com/store/apps/details?id=ru.dpizza.app" target="_blank">
                                <img src="/images/googleplay.png" alt="" style="width: 100%; max-width: 160px;">
                            </a>
                        </div>
                        <div class="mx-auto mb-2">
                            <a href="https://apps.apple.com/us/app/domino-доставка-пиццы/id6461727750" target="_blank">
                                <img src="/images/appstore.png" alt="" style="width: 100%; max-width: 160px;">
                            </a>
                        </div>
                        <div class="mx-auto mb-2">
                            <a href="https://appgallery.huawei.ru/app/C109083627" target="_blank">
                                <img src="/images/appgallery.png" alt="" style="width: 100%; max-width: 160px;">
                            </a>
                        </div>
                        <div class="mx-auto mb-2">
                            <a href="https://apps.rustore.ru/app/ru.dominopizza.app" target="_blank">
                                <img src="/images/rustore.png" alt=""
                                    style="width: 100%; max-width: 165px; height: 52px; margin-left: -2px; margin-top: -1px;">
                            </a>
                        </div>
                        <div class="mx-auto mb-2">
                            <img src="/images/app-qr-code.png" alt="" style="width: 100%; max-width: 160px;">
                        </div>

                    </div>
                    <div v-else class="d-flex flex-column">
                        <div class="mx-auto mb-2">
                            <a href="https://play.google.com/store/apps/details?id=ru.a4pizza.app" target="_blank">
                                <img src="/images/googleplay.png" alt="" style="width: 100%; max-width: 160px;">
                            </a>
                        </div>
                        <div class="mx-auto mb-2">
                            <a href="https://apps.apple.com/us/app/a4-pizza/id6452048069" target="_blank">
                                <img src="/images/appstore.png" alt="" style="width: 100%; max-width: 160px;">
                            </a>
                        </div>
                        <div class="mx-auto mb-2">
                            <a href="https://appgallery.huawei.ru/app/C108871169" target="_blank">
                                <img src="/images/appgallery.png" alt="" style="width: 100%; max-width: 160px;">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
.app-page {
    background-color: #fff;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;

    @if not $is-domino {
        border: 1px solid #000;
    }
}
</style>